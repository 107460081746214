import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext';
import Navbar from "../navbar/navbar";
import './dashboard.css';
import Keys from './API_Keys/api_keys';
import QuickChat from './QuickChat/QuickChat';
import Registrationform from './RegForm/Registrationform';
const DashboardPage = () => {
    const { user } = useAuth();
    const userEmail = user && user.username ? user.username : '';
    const [clickedBoxIndex, setClickedBoxIndex] = useState(null);

    while (!user) {
        const delay = setTimeout(() => {
        }, 1000);
    }



    const boxNames = [
        'API Keys',
        'Quick Chat',
        'My Code',
        'Enable Extensions',

    ];

    const popupContent = [
        <Keys></Keys>,
        <QuickChat />,
        "Billing Content",
        <Registrationform />,

    ];

    function Popup({ index }) {
        return (
            <div className="popup">
                <div className="popup-content">
                    <h2>{boxNames[index]}</h2>
                    <p>{popupContent[index]}</p>
                    <button onClick={() => setClickedBoxIndex(null)}>Close</button>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard-container">
            <Navbar />
            <div className="dashboard-content">
                <h1>Hello, {userEmail}!</h1>
                <div className="boxes-container">
                    {boxNames.map((name, index) => (
                        <div key={index} onClick={() => setClickedBoxIndex(index)} className="box">
                            {name}
                        </div>
                    ))}
                </div>
            </div>

            <div className="app">
                {clickedBoxIndex !== null && <Popup index={clickedBoxIndex} />}
            </div>
        </div>
    );
};

export default DashboardPage;
