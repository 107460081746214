import React, { useEffect } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../../assets/ampari-logo-official.png';

const Navbar = () => {
    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector('.navbar');
            // Check if the page content is scrolled
            if (document.documentElement.scrollTop > 0) {
                navbar.classList.add('scrolled');
            } else {
                navbar.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className="navbar">
            <div className="logo-container">
                <a href="/">
                    <img src={logo} alt="Logo" className="logo" />
                </a>
            </div>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><a href="https://ampari-a96b9f9f.mintlify.app/introduction">Documentation</a></li>
                {/*<li><Link to="/contact">Contact</Link></li>*/}
            </ul>
        </nav>
    );
}

export default Navbar;
