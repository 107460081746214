// Keys.js

import './api_keys.css';
import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { useAuth } from '../../../AuthContext'; // Import useAuth hook

function Keys() {
    const { user } = useAuth(); // Access user object from AuthContext
    const [apiKey, setApiKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const createUserToken = async () => {
        setLoading(true);
        setError(null); // Reset error state before making a new request

        try {
            const response = await axios.post('https://www.ampari-api.com/create_user_token', {
                user_token: user.token // Assuming user.token contains the token needed
            });

            if (response.status === 200) {
                setApiKey(response.data.api_token); // Update to match the API response field
            } else {
                setError('Failed to create API key: ' + response.data.error);
            }
        } catch (error) {
            setError('An error occurred: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Create a new API Key</h1>
            <button onClick={createUserToken} disabled={loading}>
                {loading ? 'Creating...' : 'Create API Key'}
            </button>
            {apiKey && (
                <p>Your new API Key: {apiKey}</p>
            )}
            {error && (
                <p style={{ color: 'red' }}>{error}</p>
            )}
        </div>
    );
}

export default Keys;
