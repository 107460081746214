import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext'; // Import useAuth hook to access signIn function
import { Form, Button, Card } from 'react-bootstrap';
import Navbar from '../../navbar/navbar';
import bcrypt from 'bcryptjs';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import './sign_up.css';


function SignInPopup() {
    const navigate = useNavigate();
    const { signIn } = useAuth(); // Using the useAuth hook to access signIn function
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    async function handleSignIn(e) {
        e.preventDefault();
        try {
            await signIn(email, password); // Using the signIn function from AuthContext
            console.log('successful user login!!!');
            // Redirect to dashboard
            navigate('/dashboard');
        } catch (error) {
            setError(error.message || 'Error signing in');
        }
    }

    return (
        <div className="popup-signin">
            <Navbar />
            <div className="popup-inner">
                <h2>Sign In</h2>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <form onSubmit={handleSignIn}>
                    <label>
                        Email:
                        <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </label>
                    <button type="submit">Sign In</button>
                </form>
            </div>
        </div>
    );
}

export default SignInPopup;