// RegistrationForm.js

import React, { useState } from 'react';
import axios from 'axios';
import './RegistrationForm.css'; // Import your CSS file for styling
import { useAuth } from '../../../AuthContext'; // Import useAuth hook to access user data

const RegistrationForm = () => {
    const { user } = useAuth(); // Assuming useAuth hook provides user object with api_token
    const [endpoint, setEndpoint] = useState('');
    const [documentation, setDocumentation] = useState('');
    const [description, setDescription] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [message, setMessage] = useState('');

    // Pre-fill API token input with user's API token
    const preFilledApiToken = user ? user.api_token : '';

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://www.ampari-api.com/python-lib/reg_api', {
                api_token: preFilledApiToken,
                endpoint,
                documentation,
                description,
                api_key: apiKey
            });

            if (response.data === 'successful registration') {
                setMessage('Registration successful!');
            } else {
                setMessage('Registration failed.');
            }
        } catch (error) {
            setMessage('Error registering API: ' + error.message);
        }
    };

    return (
        <div className="registration-container">
            <h1>Register API</h1>
            <form className="registration-form" onSubmit={handleSubmit}>
                <label htmlFor="apiToken">API Token:</label>
                <input
                    type="text"
                    id="apiToken"
                    name="apiToken"
                    value={preFilledApiToken}
                    readOnly // Make the input field read-only
                />

                <label htmlFor="endpoint">Endpoint:</label>
                <input
                    type="text"
                    id="endpoint"
                    name="endpoint"
                    value={endpoint}
                    onChange={(e) => setEndpoint(e.target.value)}
                    required
                />

                <label htmlFor="documentation">Documentation:</label>
                <textarea
                    id="documentation"
                    name="documentation"
                    value={documentation}
                    onChange={(e) => setDocumentation(e.target.value)}
                    required
                ></textarea>

                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                ></textarea>

                <label htmlFor="apiKey">API Key:</label>
                <input
                    type="text"
                    id="apiKey"
                    name="apiKey"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    required
                />

                <button type="submit">Register</button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default RegistrationForm;

