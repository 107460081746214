import React from 'react';
import Navbar from "../navbar/navbar";
import Body from "./body/body";
import Stripe from "./line_stripe/stripe";
import './homepage.css';


function HomePage() {
    return (
        <div className="main-page-container">
            <Navbar></Navbar>
            <Stripe></Stripe>
            <Body></Body>
        </div>
    );
}

export default HomePage;