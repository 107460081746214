import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './body.css';
import logo from '../../../assets/logo.png';
import code from '../../../assets/example.png';
import Documentation from '../../Documentation/documentation';
import Stripe from '../line_stripe/stripe';

function Body() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://www.ampari-api.com/message', formData);
            console.log('Message sent successfully:', response.data);
            // Optionally, show a success message to the user
            alert('Message sent successfully!');
            setFormData({ name: '', email: '', message: '' }); // Clear form fields after submission
        } catch (error) {
            console.error('Error sending message:', error);

            // Log detailed error information
            console.error('Axios error details:', error.toJSON());

            // Optionally, show an error message to the user
            alert('Failed to send message. Please try again later.');

            // Check if error.response exists to log detailed server response
            if (error.response) {
                console.error('Server response data:', error.response.data);
                console.error('Server response status:', error.response.status);
                console.error('Server response headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Request:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error message:', error.message);
            }
        }
    };

    return (
        <>
            <div className="body">
                <div className="body-text">
                    <div className="title-container">
                        <h1 className="ampari-main-titlename">Ampari</h1>
                        <h1 className="title-text"> AI for anyone | Speak with your API's </h1>
                    </div>
                    <div className="button-container">
                        <button>
                            <Link to="/about">Learn More</Link>
                        </button>

                        {/* <button>
                            <Link to="/sign_up">Sign Up</Link>
                        </button>*/}
                    </div>
                </div>

                <div className="beta-signup">
                    <h2>Sign Up For Beta</h2>
                    <form onSubmit={handleSubmit} className="signup-form">
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                        <textarea
                            className="beta-message-homepage"
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>


            </div>




        </>
    );
}

export default Body;

