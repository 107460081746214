import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check for token in cookies or localStorage
        const token = Cookies.get('token') || localStorage.getItem('token');
        console.log('Token on mount:', token);
        if (token && !user) {
            // If token exists and user is not already set, fetch user data
            console.log('Token found. Fetching user data...');
            console.log(token)
            fetchUserData(token);

        }
    }, []); // Empty dependency array to ensure useEffect runs only once on mount

    const fetchUserData = async (token) => {
        try {
            console.log(token)
            const response = await axios.get('https://www.ampari-api.com/protected', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('protected run')
            console.log(response)
            const { email } = response.data;
            const {api_token } = response.data;
            console.log('User data fetched successfully:', { token, email, api_token });
            setUser({ token, email, api_token });
        } catch (error) {
            console.error('Error fetching user data:', error);
            // If there's an error, clear token and user
            console.log('Clearing token and user...');
            Cookies.remove('token');
            localStorage.removeItem('token');
            setUser(null);
        }
    };

    const signIn = async (email, password) => {
        try {
            const response = await axios.post('https://www.ampari-api.com/signin', {
                email: email,
                password: password
            });
            console.log(response.data)
            const { access_token } = response.data;
            // Store token in cookies and localStorage
            Cookies.set('token', access_token, { expires: 1 });
            localStorage.setItem('token', access_token);
            // Fetch user data using the obtained token
            console.log('Signing in...');
            fetchUserData(access_token);
            console.log('access_token');
            console.log(access_token);
        } catch (error) {
            console.error('Error signing in:', error);
            throw new Error('Error signing in');
        }
    };

    const signOut = () => {
        console.log('Signing out...');
        // Remove token from cookies and localStorage
        Cookies.remove('token');
        localStorage.removeItem('token');
        setUser(null);
        console.log('Sign-out successful!');
    };

    const isAuthenticated = () => {
        // Check if user is authenticated based on token
        return !!user;
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};
