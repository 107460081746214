import React from 'react';
import './about.css'; // Create this CSS file for styling
import Navbar from '../navbar/navbar';
import Stripe from '../homepage/line_stripe/stripe';


const About = () => {
    return (
        <>
            <Navbar> </Navbar>
            <div className="about-page-container">
                <h1>About Us</h1>

                <section>
                    <p>
                        Welcome to our website! We are dedicated to providing the best service possible.
                    </p>

                    <h2>Our Mission</h2>
                    <p>
                        Our mission is to deliver a platform for LLM developers to connect their product to mutliple services all in a single integration.
                    </p>

                    <h2>Our Team</h2>
                    <p>
                        Founder & CEO

                    </p>
                    <p>
                    </p>

                    <h2>Contact Us</h2>
                    <p>
                        If you have any questions, feel free to reach out to us at founders@ampariai.com.
                    </p>
                </section>
            </div>
        </>
    );
};

export default About;
