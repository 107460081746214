import React, { useState, useEffect, useRef } from 'react';
import './quickChat.css';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';

const QuickChat = () => {
    const { user } = useAuth();
    const apiToken = user ? user.api_token : '';

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useRef(null);

    useEffect(() => {
        setMessages([{ id: 1, sender: 'Support', text: 'Hello! How can I help you today?' }]);
    }, [apiToken]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const userMessage = {
            id: messages.length + 1,
            sender: 'User',
            text: newMessage.trim(),
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setNewMessage('');

        try {
            const response = await axios.post('https://www.ampari-api.com/python-lib/serve', {
                api_token: apiToken,
                prompt: newMessage.trim(),
            });

            const responseData = response.data;
            const responseMessage = {
                id: messages.length + 2,
                sender: 'Support',
                text: JSON.stringify(responseData),
            };
            setMessages((prevMessages) => [...prevMessages, responseMessage]);
        } catch (error) {
            console.error('Error sending message:', error.message);
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-messages-container">
                <div className="chat-messages">
                    {messages.map((message) => (
                        <div key={message.id} className={`message ${message.sender.toLowerCase()}`}>
                            <div className="message-text">{message.text}</div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={handleInputChange}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default QuickChat;
