import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import HomePage from "./components/homepage/homepage";
import RegisterPopup from './components/homepage/sign_up/sign_up';
import SignInPopup from './components/homepage/sign_in/sign_in';
import DevDash from './components/Developer_Dashboard/dev_dashboard';
import { AuthProvider, useAuth } from './AuthContext';
import DashboardPage from './components/Dashboard/dashboard'; // Import your DashboardPage component
import About from './components/About/about';
import Documentation from './components/Documentation/documentation';
import {useEffect, useState } from 'react';

// PrivateRoute component to handle authenticated routes
const PrivateRoute = ({ element, ...props }) => {
    const { user } = useAuth();
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldRender(true);
        }, 2000); // 1000 milliseconds = 1 second

        // Clean up the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    if (!shouldRender) {
        // Optionally render a loading spinner or placeholder
        return null;
    }

    return user ? element : <Navigate to="/sign_in" />;
};

const App = () => {
    return (
            <Router>
                <div>
                    {/* Your navigation or header can go here */}

                    <Routes>
                        {/* Define routes for each page */}
                        <Route path="/" element={<HomePage />} />
                        <Route path="/sign_up" element={<RegisterPopup />} />
                        {/* Use PrivateRoute as a route element */}
                        { <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} /> }
                        <Route path ="/dev-dash" element = {<DevDash/>} />
                        <Route render={() => <div>404 Not Found</div>} />
                        <Route path="/sign_in" element={<SignInPopup />} />
                        <Route path="/about" element={<About/>} />
                        {/*<Route path="/documentation" element={<Documentation/>} /> */}
                    </Routes>
                </div>
            </Router>
    );
};

export default App;