import React from 'react';
import "./stripe.css";


function Stripe() {
    return (
        <div className="body2">

        </div>
    );
}

export default Stripe;