import React, { useState } from 'react';
import './dev_dashboard.css'
import Navbar from '../navbar/navbar'
const DevDash = () => {
    const [endpoint, setEndpoint] = useState('');
    const [description, setDescription] = useState('');
    const [documentation, setDocumentation] = useState('');
    const [savedEndpoint, setSavedEndpoint] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSavedEndpoint({
            endpoint,
            description,
            documentation,
        });
    };

    return (
        <div className = "page-container">
            <Navbar></Navbar>
            <div className="container">
                <h1>Create API Endpoint</h1>
                <form className="form" onSubmit={handleSubmit}>
                    <label htmlFor="endpoint">Endpoint</label>
                    <input
                        type="text"
                        id="endpoint"
                        value={endpoint}
                        onChange={(e) => setEndpoint(e.target.value)}
                        required
                    />
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="4"
                        required
                    />
                    <label htmlFor="documentation">Documentation</label>
                    <textarea
                        id="documentation"
                        value={documentation}
                        onChange={(e) => setDocumentation(e.target.value)}
                        rows="6"
                        required
                    />
                    <button type="submit">Save Endpoint</button>
                </form>
                {savedEndpoint && (
                    <div className="saved-endpoint">
                        <h2>Saved Endpoint</h2>
                        <p><strong>Endpoint:</strong> {savedEndpoint.endpoint}</p>
                        <p><strong>Description:</strong> {savedEndpoint.description}</p>
                        <p><strong>Documentation:</strong> {savedEndpoint.documentation}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DevDash;
