import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import Navbar from '../../navbar/navbar';
import axios from 'axios';
import './sign_up.css';

function RegisterPopup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    async function handleRegister(e) {
        e.preventDefault();

        try {
            const response = await axios.post('https://www.ampari-api.com/create_user', {
                email,
                password,
                first_name: firstName,
                last_name: lastName
            });

            if (response.status === 201) {
                setSuccess('User registered successfully');
                setEmail('');
                setPassword('');
                setFirstName('');
                setLastName('');
                setError(null);
            } else {
                setError(response.data.message || 'Error registering user');
                setSuccess(null);
            }
        } catch (error) {
            setError('Error registering user: ' + error.message);
            setSuccess(null);
        }
    }

    return (
        <div className="register-popup">
            <Navbar />
            <Card className="register-card">
                <Card.Body>
                    <Card.Title>Register</Card.Title>
                    <Form onSubmit={handleRegister}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Register
                        </Button>
                    </Form>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">{success}</p>}
                </Card.Body>
            </Card>
        </div>
    );
}

export default RegisterPopup;
